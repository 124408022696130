<template>
    <div class="video-page">
        <div class="left">
            <menu-tree ref="mTree" @checkClick="checkClick"></menu-tree>
        </div>
        <div class="videos-wrapper">
            <videoPlayer
                v-for="video in videos"
                ref="video"
                :key="video.id"
                :class="videoLayoutClass(video)"
                :params="getParams(video)"
                class="vCommon"
                :vtitle="getTitle(video)"
                @talk="changeTalk"
            ></videoPlayer>
        </div>
    </div>
</template>
<script>
    import videoPlayer from "@/components/pages/admin/videoPlayer/flvPlayer";
    import menuTree from "../common/mTree";
    export default {
        name: "index",
        computed: {
            videoLayoutClass() {
                return video => {
                    if (this.videos.length === 1) {
                        return "v1";
                    } else if (this.videos.length <= 4) {
                        return "v4";
                    }
                     else if (this.videos.length <= 6) {
                        if (video.channelId == 1) {
                            return "v_6_1";
                        } else {
                            return "v6";
                        }
                    } else if (this.videos.length <= 9) {
                        return "v9";
                    } else {
                        return "v16";
                    }
                };
            },
            getTitle() {
                return video => {
                    return video.plateNum + "-" + video.deviceId + "-" + video.channelId;
                };
            },
            /**
             * @param clientId 设备编号
             * @param channelId 通道号
             */
            getParams() {
                return function(video) {
                    return {
                        clientId: video.deviceId,
                        channelId: video.channelId
                    };
                };
            }
        },
        methods: {
            checkClick(data) {
                const index = this.videos.findIndex(v => v.id == data.id);
                if (index != -1) {
                    this.videos.splice(index, 1);
                } else {
                    if (this.videos.length == 16) {
                        const video = this.videos.shift();
                        this.$refs.mTree.unChecked(video.id);
                    }
                    this.videos.push({
                        plateNum: data.plateNum,
                        deviceId: data.deviceId,
                        channelId: data.channelId,
                        id: data.id
                    });
                }
            },
            changeTalk(uuid, openFunc) {
                if (!this.talkUUid) {
                    openFunc();
                    this.talkUUid = uuid;
                } else {
                    if (this.talkUUid === uuid) {
                        this.talkUUid = "";
                    } else {
                        this.$message.error({
                            message: "请取消正在对讲的通道",
                            duration: 3000
                        });
                    }
                }
            }
        },
        mounted() {},
        data() {
            return {
                videos: [], // 初始化的视频通道
                talkUUid: ""
            };
        },
        components: {
            videoPlayer,
            menuTree
        }
    };
</script>
<style lang="scss" scoped>
    @import "../common/style.scss";
</style>
